
.map-container {
    width: 100vw;
    height: 100vh;
}

#map { 
    width: 100%;
    height: 100%;
}

.marker { 
    width: 100px;
    height: 100px;
}

.basemap-selector {
    position: absolute;
    width: 10vw;
    top: 10px;
    right: 50px;
    z-index: 1000;
    background: white;
    padding: 5px;
    border-radius: 4px;
}

.basemap-selector select { 
    width: 100%;
}